import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../layout/index.vue'),
    children: [
      {
        // 合同管理
        path: '/home',
        name: 'home',
        component: () => import('../views/userAdmin/home.vue'),
        beforeEnter: (to, from, next) => {
          next()
          // if(localStorage.getItem('userToken')){
          //   next()
          // }else{
          //   next('/')
          //   // next()
          // }

        },
        meta: {
          title: "首页",
          need_token: true,
          permission: ["1", "2", "3", "4", "5"]
        }
      },
      {
        // 合同详情
        path: '/contractDetail',
        name: 'contractDetail',
        component: () => import('../views/userAdmin/contractDetail.vue'),
        beforeEnter: (to, from, next) => {
          next()
          // if(localStorage.getItem('userToken')){
          //   next()
          // }else{
          //   next('/')
          //   // next()
          // }

        },
        meta: {
          title: "首页",
          need_token: true,
          permission: ["1", "2", "3", "4", "5"]
        }
      },
    ]
  },
  {
    // 合同
    path: '/contractH5',
    name: 'contractH5',
    component: () => import('../views/userAdmin/contractH5.vue'),
    beforeEnter: (to, from, next) => {
      next()
    },
    meta: {
      title: "首页",
      need_token: false,
      permission: ["1", "2", "3", "4", "5"]
    }
  },
  {
    // 去签名
    path: '/sign',
    name: 'sign',
    component: () => import('../views/userAdmin/sign.vue'),
    beforeEnter: (to, from, next) => {
      next()
    },
    meta: {
      title: "签名",
      need_token: false,
      permission: ["1", "2", "3", "4", "5"]
    }
  },
  {
    // 下载预览页
    path: '/downloadPage',
    name: 'downloadPage',
    component: () => import('../views/userAdmin/downloadPage.vue'),
    beforeEnter: (to, from, next) => {
      next()
    },
    meta: {
      title: "签名",
      need_token: false,
      permission: ["1", "2", "3", "4", "5"]
    }
  },
  {
    // 预览pdf
    path: '/viewPdf',
    name: 'viewPdf',
    component: () => import('../views/userAdmin/viewPdf.vue'),
    beforeEnter: (to, from, next) => {
      next()
    },
    meta: {
      title: "签名",
      need_token: false,
      permission: ["1", "2", "3", "4", "5"]
    }
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
