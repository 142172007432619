import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/style/reset.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhcn from 'element-plus/es/locale/lang/zh-cn'
import asign from 'vue-asign'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(store)
    .use(asign)
    .use(router)
    .use(ElementPlus, { locale: zhcn }).mount('#app')
