import { createStore } from 'vuex'

export default createStore({
  state: {
    esginImg:'',//签名
    contractData:{},
    pdfUrl:'',
  },
  getters: {
  },
  mutations: {
    vuex_esginData(state,value){
      state.esginImg = value
    },
    vuex_contractData(state,value){
      state.contractData = value
    },
    vuex_pdfUrl(state,value){
      state.pdfUrl = value
    }
  },
  actions: {
  },
  modules: {
  }
})
